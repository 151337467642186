import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import Etapes from './Components/Etapes.jsx';
import Header from './Components/Header.jsx';

const PriseRdv = () => {
	const [espaces, setEspaces] = useState([]);
	const [styles, setStyles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [etape, setEtape] = useState(1);
	const [tout, setTout] = useState([]);
	const [imageHeader, setImageHeader] = useState('');
	const [selectionEspace, setSelectionEspace] = useState([]);
	const [selectionStyle, setSelectionStyle] = useState([]);
	const [selectionStyleFinal, setSelectionStyleFinal] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		setLoading(true);
		axios.get('/wp-json/acf/v3/options/options').then((response) => {
			setTout(response.data.acf);
			setEspaces(response.data.acf.espaces);
			setStyles(response.data.acf.styles);
			setImageHeader(response.data.acf.image_header);
			setLoading(false);
		});
	};

	const scrollToRdv = () => {
		document.querySelector('#rdv_anchor').scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	};

	const handleEtapeSuivante = () => {
		setEtape(etape + 1);
	};

	const handleEtapePrecedente = () => {
		setEtape(etape - 1);
	};

	useEffect(() => {
		scrollToRdv();
	}, [etape]);

	return (
		<div className="prise_rdv">
			<Header image={imageHeader} etape={etape} setEtape={setEtape} />
			<div className="uk-container uk-container-large">
				<Etapes
					etape={etape}
					setEtape={setEtape}
					espaces={espaces}
					styles={styles}
					loading={loading}
					selectionEspace={selectionEspace}
					setSelectionEspace={setSelectionEspace}
					selectionStyle={selectionStyle}
					setSelectionStyle={setSelectionStyle}
					selectionStyleFinal={selectionStyleFinal}
					setSelectionStyleFinal={setSelectionStyleFinal}
					tout={tout}
				/>
				<div className="nav_etapes">
					{etape > 1 && etape < 4 ? (
						<button
							onClick={handleEtapePrecedente}
							style={{ marginRight: '24px' }}
						>
							{/* {etape}/3 - */} Etape précédente
						</button>
					) : null}
					{etape < 3 ? (
						<button onClick={handleEtapeSuivante}>
							{/* {etape}/3 -  */}Etape suivante
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
};

document.addEventListener('DOMContentLoaded', function () {
	const el = document.getElementById('prise_rdv');
	if (el) {
		ReactDOM.render(<PriseRdv />, el);
	}
});
