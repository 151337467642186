import React, { useState } from 'react';

const Selection = ({
	selectionEspace,
	setSelectionEspace,
	selectionStyle,
	setSelectionStyle,
	selectionStyleFinal,
	setSelectionStyleFinal,
	tout,
}) => {
	const testing = tout.espaces.filter((item) => {
		return selectionEspace.includes(item.nom);
	});
	return (
		<>
			<div uk-scrollspy="cls: uk-animation-slide-left-small; repeat: true">
				<h2>Affinez votre séléction</h2>
				{testing.map((item, index) => {
					return (
						<SelectRows
							key={index}
							styles={item.styles_espace}
							selectionStyle={selectionStyle}
							titre={item.nom}
							icone={item.icone}
							selectionStyleFinal={selectionStyleFinal}
							setSelectionStyleFinal={setSelectionStyleFinal}
						/>
					);
				})}
			</div>
		</>
	);
};

export default Selection;

const SelectRows = ({
	styles,
	selectionStyle,
	titre,
	icone,
	selectionStyleFinal,
	setSelectionStyleFinal,
}) => {
	const result = Object.entries(styles);
	return (
		<div className="selectrows">
			{result.map((styles, index) => {
				return (
					<div key={index} className="selectrow">
						{selectionStyle.includes(
							styles[0][0].toUpperCase() + styles[0].slice(1)
						) ? (
							<>
								<div>
									<img src={icone} alt="" />
									<h4>{titre}</h4>
									<div>
										<h5>{styles[0]}</h5>
									</div>
								</div>
								<div>
									<div data-uk-slider>
										<div className="uk-position-relative">
											<div className="uk-slider-container">
												<div className="uk-slider-items uk-grid">
													{Object.values(
														styles[1]
													).map((style, index) => {
														return (
															<Style
																key={index}
																nom={style.nom}
																image={
																	style.image
																}
																selectionStyleFinal={
																	selectionStyleFinal
																}
																setSelectionStyleFinal={
																	setSelectionStyleFinal
																}
															/>
														);
													})}
												</div>
											</div>
											<a
												className="uk-position-center-left-out uk-slidenav-large uk-icon uk-slidenav-previous uk-slidenav"
												href="#"
												data-uk-slider-item="previous"
												data-uk-slidenav-previous
											></a>
											<a
												className="uk-position-center-right-out uk-slidenav-large uk-icon uk-slidenav-next uk-slidenav"
												href="#"
												data-uk-slider-item="next"
												data-uk-slidenav-next
											></a>
										</div>
									</div>
								</div>
							</>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

const Style = ({ nom, image, selectionStyleFinal, setSelectionStyleFinal }) => {
	const [selected, setSelected] = useState(false);
	return (
		<div>
			<div
				className="style_item"
				style={{
					borderColor: selected ? '#333333' : '#cdcdcd',
					borderWidth: selected ? '2px' : '1px',
				}}
				onClick={() => {
					if (!selectionStyleFinal.find((espace) => espace == nom)) {
						setSelectionStyleFinal(selectionStyleFinal.concat(nom));
						setSelected(true);
					} else {
						setSelectionStyleFinal(
							selectionStyleFinal.filter(
								(espace) => espace != nom
							)
						);
						setSelected(false);
					}
				}}
			>
				<img src={image} />
				<p>{nom}</p>
				<span
					className="checked"
					style={
						({ color: selected ? 'white' : 'white' },
						{ backgroundColor: selected ? '#95c11f' : '#cdcdcd' })
					}
				>
					<i className="far fa-check"></i>
				</span>
			</div>
		</div>
	);
};
