import React from 'react';

const Confirmation = () => (
	<>
		<section className="uk-section">
			<div className="uk-container uk-container-large">
				<h1>
					Merci de votre intérêt.<br></br> Notre équipe vous
					contactera très rapidement pour planifier votre rendez-vous.
				</h1>
			</div>
		</section>
	</>
);

export default Confirmation;
