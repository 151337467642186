// import 'uikit/dist/js/uikit.js';
import '../../node_modules/uikit/dist/js/uikit.js';
import '../scss/style.scss';

import './priserdv.jsx';

import '@fortawesome/fontawesome-pro/js/all';
import { now } from 'moment';

jQuery(document).ready(function ($) {
	//mosaique item link
	$(
		'.mosaique_carrelage_item, .mosaique_salledebain_item, .mosaique_realisations_item'
	).click(function (e) {
		e.preventDefault();
		// console.log($(this).data('link'));
		window.location.href = $(this).data('link');
	});

	/******************************
     BOTTOM SCROLL TOP BUTTON
     ******************************/

	// declare variable
	var scrollTop = $('.back-to-top');

	$('body').scroll(function () {
		// declare variable
		var topPos = $(this).scrollTop();

		// if user scrolls down - show scroll to top button
		if (topPos > 100) {
			$(scrollTop).css('opacity', '1');
		} else {
			$(scrollTop).css('opacity', '0');
		}
	}); // scroll END

	//Click event to scroll to top
	$(scrollTop).click(function () {
		$('html, body').animate(
			{
				scrollTop: 0,
			},
			800
		);
		return false;
	}); // click() scroll top EMD
	/*******************************/

	$('#text-7 .textwidget').wrap('<a href="/votre-projet/"></a>');

	//header sticky
	$('body').scroll(function () {
		var sticky = $('#masthead'),
			scroll = $('body').scrollTop();
		/* 		var top =
			(document.documentElement && document.documentElement.scrollTop) ||
			document.body.scrollTop;
		console.log('debug scroll Edge : ' + top + '_' + now()); */

		if (scroll >= 300) {
			sticky.addClass('sticky');
		} else {
			sticky.removeClass('sticky');
		}
	});
});
