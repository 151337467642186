import React from 'react';
import besoinImg from '../../img/picto-besoin.svg';
import selectionImg from '../../img/picto-selection.svg';
import rdvImg from '../../img/picto-rdv.svg';

const Header = ({ image, etape, setEtape }) => {
	return (
		<>
			<div className="header uk-inline uk-padding uk-padding-remove-horizontal uk-padding-remove-top">
				<img src={image} alt="" className="img_header_projet" />
				<div className="uk-overlay uk-cover uk-light uk-width-1-1">
					<div className="uk-container uk-container-large">
						<h2 id="rdv_anchor" className="uk-text-center">
							Donnez vie à votre projet en 3 étapes
						</h2>
						<div
							className="icones uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-middle uk-grid-match"
							data-uk-grid="margin: '';"
						>
							<div>
								<div className="icone uk-flex uk-flex-column uk-flex-row uk-flex-center uk-flex-middle@m">
									<div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
										<img
											data-uk-svg
											src={besoinImg}
											alt="logo"
										/>
										<p>Vos besoins</p>
									</div>
									<Active
										etape={etape}
										chiffre={1}
										setEtape={setEtape}
									/>
								</div>
							</div>

							<div>
								<div className="icone uk-flex uk-flex-column uk-flex-center uk-flex-middle@m">
									<div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
										<img
											data-uk-svg
											src={selectionImg}
											alt="logo"
										/>
										<p>Votre sélection</p>
									</div>
									<Active
										etape={etape}
										chiffre={2}
										setEtape={setEtape}
									/>
								</div>
							</div>

							<div>
								<div className="icone uk-flex uk-flex-column uk-flex-center uk-flex-middle@m">
									<div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle">
										<img
											data-uk-svg
											src={rdvImg}
											alt="logo"
										/>
										<p>Votre rendez-vous</p>
									</div>
									<Active
										etape={etape}
										chiffre={3}
										setEtape={setEtape}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;

const Active = ({ etape, chiffre, setEtape }) => (
	<span
		className={etape == chiffre ? `active` : ``}
		onClick={() => setEtape(chiffre)}
	>
		{chiffre}
	</span>
);
