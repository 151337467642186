import React, { useState } from 'react';

const Besoins = ({
	espaces,
	loading,
	selectionEspace,
	setSelectionEspace,
	selectionStyle,
	setSelectionStyle,
	styles,
}) => {
	if (loading) {
		return <span uk-spinner="ratio: 3"></span>;
	}
	if (espaces && styles) {
		return (
			<>
				<div uk-scrollspy="cls: uk-animation-slide-left-small; repeat: true">
					<h3 className="uk-text-center">Sélectionnez vos besoins</h3>
					<h4 className="uk-text-center uk-text-uppercase">
						Sélectionnez les espaces concernés par votre besoin
					</h4>
					<div className="choix_espace">
						{espaces.map(({ nom, icone }, index) => {
							return (
								<Besoin
									key={index}
									nom={nom}
									icone={icone}
									selectionEspace={selectionEspace}
									setSelectionEspace={setSelectionEspace}
								/>
							);
						})}
					</div>

					<h3 className="uk-text-center">
						Sélectionnez les styles qui vous plaisent
					</h3>
					<h4>(Vous pouvez ignorer cette étape)</h4>
					<div className="choix_style">
						{styles.map(({ nom, image }, index) => {
							return (
								<Style
									key={index}
									nom={nom}
									image={image}
									selectionStyle={selectionStyle}
									setSelectionStyle={setSelectionStyle}
								/>
							);
						})}
					</div>
				</div>
			</>
		);
	}
};

export default Besoins;

const Besoin = ({ nom, icone, selectionEspace, setSelectionEspace }) => {
	const [selected, setSelected] = useState(selectionEspace.includes(nom));
	return (
		<div
			className=""
			style={{
				borderColor: selected ? '#333333' : '#cdcdcd',
				borderWidth: selected ? '2px' : '1px',
			}}
			onClick={() => {
				if (!selectionEspace.find((espace) => espace == nom)) {
					setSelectionEspace(selectionEspace.concat(nom));
					setSelected(true);
				} else {
					setSelectionEspace(
						selectionEspace.filter((espace) => espace != nom)
					);
					setSelected(false);
				}
			}}
		>
			<img src={icone} data-uk-svg />
			<p>{nom}</p>
			<span
				className="checked"
				style={
					({ color: selected ? 'white' : 'white' },
					{ backgroundColor: selected ? '#95c11f' : '#cdcdcd' })
				}
			>
				<i className="far fa-check"></i>
			</span>
		</div>
	);
};

const Style = ({ nom, image, selectionStyle, setSelectionStyle }) => {
	const [selected, setSelected] = useState(selectionStyle.includes(nom));
	return (
		<div
			style={{
				borderColor: selected ? '#333333' : '#cdcdcd',
				borderWidth: selected ? '2px' : '1px',
			}}
			onClick={() => {
				if (!selectionStyle.find((style) => style == nom)) {
					setSelectionStyle(selectionStyle.concat(nom));
					setSelected(true);
				} else {
					setSelectionStyle(
						selectionStyle.filter((style) => style != nom)
					);
					setSelected(false);
				}
			}}
		>
			<img src={image} />
			<p>{nom}</p>
			<span
				className="checked"
				style={
					({ color: selected ? 'white' : 'white' },
					{ backgroundColor: selected ? '#95c11f' : '#cdcdcd' })
				}
			>
				<i className="far fa-check"></i>
			</span>
		</div>
	);
};
