import React from 'react';
import Rdv from './Rdv.jsx';
import Besoins from './Besoins.jsx';
import Selection from './Selection.jsx';
import Confirmation from './Confirmation';
import Spinner from './Spinner.jsx';

const Etapes = ({
					etape,
					setEtape,
					espaces,
					styles,
					selectionEspace,
					setSelectionEspace,
					selectionStyle,
					selectionStyleFinal,
					setSelectionStyleFinal,
					setSelectionStyle,
					loading,
					tout,
				}) => {
	const displayEtape = (param) => {
		switch (param) {
			case 1:
				return (
					<Besoins
						espaces={espaces}
						etape={etape}
						styles={styles}
						setSelectionEspace={setSelectionEspace}
						selectionEspace={selectionEspace}
						setSelectionStyle={setSelectionStyle}
						selectionStyle={selectionStyle}
					/>
				);
			case 2:
				return (
					<Selection
						selectionEspace={selectionEspace}
						selectionStyle={selectionStyle}
						tout={tout}
						selectionStyleFinal={selectionStyleFinal}
						setSelectionStyleFinal={setSelectionStyleFinal}
					/>
				);
			case 3:
				return (
					<Rdv
						selectionEspace={selectionEspace}
						selectionStyle={selectionStyle}
						setEtape={setEtape}
						selectionStyleFinal={selectionStyleFinal}
					/>
				);
			case 4:
				return (
					<Confirmation/>
				);
		}
	};
	return <>{loading ? <Spinner/> : <div className="etapes"> {displayEtape(etape)}</div>}</>;
};

export default Etapes;
