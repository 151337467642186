import React from 'react';

const Spinner = () => {
	return (
		<>
			<div className="uk-position-relative uk-padding-large">
				<div
					className="uk-position-center"
					data-uk-spinner="ratio: 3"
				></div>
			</div>
		</>
	);
};

export default Spinner;
