import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import fr from 'date-fns/locale/fr';
var moment = require('moment');

registerLocale('fr', fr);

const Rdv = ({
	selectionEspace,
	selectionStyle,
	setEtape,
	selectionStyleFinal,
}) => {
	const [startDate, setStartDate] = useState(new Date());
	const [startTime, setStartTime] = useState(new Date());
	return (
		<>
			<div uk-scrollspy="cls: uk-animation-slide-left-small; repeat: true">
				<h3 className="uk-text-center">Votre rendez-vous</h3>
				<div className="uk-card uk-card-body carte_form">
					<Formik
						initialValues={{
							prenom: '',
							nom: '',
							email: '',
							telephone: '',
							codepostal: '',
							ville: '',
							type: '',
						}}
						validationSchema={Yup.object({
							// desactivé pour DEV/
							prenom: Yup.string()
								.min(3, 'Minimum 3 lettres')
								.required('Champs requis'),
							nom: Yup.string()
								.min(3, 'Minimum 3 lettres')
								.required('Champs requis'),
							email: Yup.string()
								.email('E-mail invalide')
								.required('Champs requis'),
							telephone: Yup.string().required('Champs requis'),
							codepostal: Yup.string().required('Champs requis'),
							ville: Yup.string().required('Champs requis'),
							// date: Yup.string().required('Champs requis'),
							// heure: Yup.string().required('Champs requis'),
							type: Yup.string().required('Champs requis'),
						})}
						onSubmit={(values, { setSubmitting }) => {
							const dateChoisie = moment(startDate).format(
								'DD-MM-YYYY'
							);
							const heureChoisie = moment(startTime).format(
								'HH:mm'
							);

							const data = {
								action: 'projet_action',
								selectionEspace,
								selectionStyle,
								selectionStyleFinal,
								formulaire: values,
								date: dateChoisie,
								heure: heureChoisie,
							};

							axios
								.post(ajaxurl, qs.stringify(data))
								.then((res) => {
									// const resultat = document.createElement('div');
									// resultat.innerHTML = res.data;
									// document
									// 	.getElementById('prise_rdv')
									// 	.appendChild(resultat);
								})
								.then(() => setEtape(4));
						}}
					>
						<Form>
							<h4
								className="uk-text-center"
								style={{ fontSize: '36px', fontWeight: 'bold' }}
							>
								Vos coordonnées
							</h4>
							<div
								className="uk-grid-large uk-child-width-1-2@s"
								data-uk-grid
							>
								<div>
									<Field
										name="prenom"
										type="text"
										className={'uk-input'}
										placeholder={'Prénom'}
									/>
									<ErrorMessage
										name="prenom"
										component="span"
									/>
								</div>
								<div>
									<Field
										name="nom"
										type="text"
										className={'uk-input'}
										placeholder={'Nom'}
									/>
									<ErrorMessage name="nom" component="span" />
								</div>
								<div>
									<Field
										name="telephone"
										type="tel"
										className={'uk-input'}
										placeholder={'Téléphone'}
									/>
									<ErrorMessage
										name="telephone"
										component="span"
									/>
								</div>
								<div>
									<Field
										name="email"
										type="email"
										className={'uk-input'}
										placeholder={'E-mail'}
										id="email"
									/>
									<ErrorMessage
										name="email"
										component="span"
									/>
								</div>
								<div>
									<Field
										name="codepostal"
										type="text"
										className={'uk-input'}
										placeholder={'Code postal'}
									/>
									<ErrorMessage
										name="codepostal"
										component="span"
									/>
								</div>
								<div>
									<Field
										name="ville"
										type="text"
										className={'uk-input'}
										placeholder={'Ville'}
									/>
									<ErrorMessage
										name="ville"
										component="span"
									/>
								</div>
							</div>
							<h4
								className="uk-text-center"
								style={{ fontSize: '36px', fontWeight: 'bold' }}
							>
								Vos disponibilités
							</h4>
							<div
								className="uk-child-width-1-2@m uk-flex uk-flex-column uk-flex-center uk-flex-middle"
								data-uk-grid
							>
								<div className="uk-position-relative uk-padding-small">
									<DatePicker
										selected={startDate}
										locale="fr"
										onChange={(date) => setStartDate(date)}
										dateFormat="dd / MM / Y"
										todayButton="Aujourd'hui"
										placeholderText="JJ/MM/AAAA"
										name="date"
										className="uk-input uk-text-center"
									/>
									<label className="picker">
										<i className="fal fa-calendar"></i>
									</label>
								</div>
								<div className="uk-position-relative uk-padding-small">
									<DatePicker
										selected={startTime}
										locale="fr"
										onChange={(date) => setStartTime(date)}
										todayButton="Aujourd'hui"
										placeholderText="JJ/MM/AAAA"
										name="date"
										className="uk-input uk-text-center"
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={60}
										timeCaption="Heure"
										dateFormat="HH:mm"
									/>
									<label className="picker">
										<i className="fal fa-clock"></i>
									</label>
								</div>
								<div className="uk-padding-small">
									<Field
										name="type"
										as="select"
										placeholder="Type de RDV"
										className="uk-select"
										style={{
											// width: '300px',
											// maxWidth: '80vw',
											textAlign: 'center',
										}}
									>
										<option value="" disabled>
											Type de RDV
										</option>
										<option value="tel">
											Téléphonique
										</option>
										<option value="physique">
											Physique
										</option>
									</Field>
									<ErrorMessage
										name="type"
										component="span"
									/>
								</div>
							</div>
							<button
								type="submit"
								style={{
									display: 'block',
									margin: '0 auto',
									transform: 'translateX(-18px)',
									textTransform: 'uppercase',
									marginTop: '30px',
								}}
							>
								Valider
							</button>
						</Form>
					</Formik>
				</div>
			</div>
		</>
	);
};

export default Rdv;

const Datepicker = () => {
	const [startDate, setStartDate] = useState(new Date());

	return (
		<DatePicker
			selected={startDate}
			locale="fr"
			onChange={(date) => setStartDate(date)}
			dateFormat="dd / MM / Y"
			todayButton="Aujourd'hui"
			isClearable
			placeholderText="JJ/MM/AAAA"
			name="date"
		/>
	);
};
